import * as React from "react";
import opsio from "../images/logo_opsio.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import AddressIcon from "../images/assets/addressIcon.svg";
import PhoneIcon from "../images/assets/phoneIcon.svg";
import MailIcon from "../images/assets/mailIcon.svg";
import { Link } from "gatsby";
import LinkdinIcon from "../images/assets/linkdinIcon.svg";
import TwitterIcon from "../images/assets/twitterIcon.svg";
import FacebookIocn from "../images/assets/facebookIocn.svg";
import message from "../images/message.png";
import arrowRight from "../images/media-arrow-right.svg";
const url = process.env.GATSBY_API_URL;

const Footer = ({ data, requirements, locale, hideCta,campaignLink }) => {
  const footerData = data.heading;
  const services = data.values.SERVICES;
  const platforms = data.values.PLATFORMS;
  const technology = data.values.TECHNOLOGIES;
  const technology2 = data.values.TECHNOLOGIES2;
  const industries = data.values.INDUSTRIES;
  const resources = data.values.RESOURCES;
  const company = data.values.COMPANY;
  // const [email, setEmail] = useState("");

  // const [success, setSuccess] = useState(false)

  // const [errorMsg, setErrorMsg] = useState();
  // useEffect(() => email.length == 0 && setErrorMsg(''), [email.length])
  // const handleSubmit = (e) => {
  //     e.preventDefault();

  //     const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //     if (email.match(mailformat)) {
  //         axios.post(`${url}/gettingstarted-forms`, { email: email })
  //             .then(() => {
  //                 setSuccess(true)
  //             })
  //             .catch(() => setSuccess(false));
  //         setEmail("")
  //         setErrorMsg("")
  //     }

  //     else {
  //         setErrorMsg('* Please Enter the Valid Email');
  //     }
  // }
  // useEffect(() => {
  //     setTimer(3000);
  // }, [success]);
  // const setTimer = (delay) => {
  //     setTimeout(() => setSuccess(false), delay);
  // };

  return (
    <>
      {!hideCta && (
        <div className="tellUsAbout" id="gettingstarted">
          <div className="partner_container">
            <div className="tellUsAboutHeading">{requirements.title}</div>
            <div className="tellUsAboutEnquireNow">
              <div className="tellUsAboutDescription">{requirements.des}</div>
              <div className="opsBtnwrap ">
                <Link className="opsBtnlink" to={ campaignLink ? "#contact-us":"/contact-us/"}>
                  <p>{requirements.btn}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="opsioFooter">
        {!hideCta && (
          <div
            className="footer-icon"
            style={{
              backgroundImage: `url(${
                require("../images/footer-icon.svg").default
              })`,
            }}
          ></div>
        )}
        <div className="footerWrap">
          <div className="footerLinksbox">
            <div className="linksCol">
              <label className="flinksHead">{footerData[0]}</label>
              <ul className="linksList">
                {services.map((service, index) => {
                  return (
                    <li key={index} className="listItem">
                      <Link to={`/${service.src}/`}>{service.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="linksCol">
              <label className="flinksHead">{footerData[1]}</label>
              <ul className="linksList">
                {technology2 && technology2.map((service, index) => {
                  return (
                    <li key={index} className="listItem">
                      <Link to={`/${service.src}`}>
                        {service.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <ul className="linksList">
                {technology.map((service, index) => {
                  return (
                    <li key={index} className="listItem">
                      <Link to={`/technologies/#${service.src}`}>
                        {service.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              
            </div>
            <div className="linksCol">
              <label className="flinksHead">{footerData[2]}</label>
              <ul className="linksList">
                {industries.map((service, index) => {
                  return (
                    <li key={index} className="listItem">
                      <Link to={`/industries/#${service.src}`}>
                        {service.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="linksCol">
              <label className="flinksHead">{footerData[3]}</label>
              <ul className="linksList">
                {resources.slice(0, 2).map((service, index) => (
                  <li className="listItem" key={index}>
                    <Link to={`${service.src}/`}>{service.name}</Link>
                  </li>
                ))}
                {/* <li className="listItem d-none" key="blog-list">
                  <Link to={`/blog-list/`}>All Blog Articles</Link>
                </li> */}
                <li
                  className="listItem d-none"
                  key="aws-public-support-statement"
                >
                  <Link to={`/services/aws-public-support-statement/`}>
                    AWS Public Support Statement
                  </Link>
                </li>
                <li
                  className="listItem d-none"
                  key="sewn-technology-case-study"
                >
                  <Link to={`/sewn-technology-case-study/`}>
                    SEWN Case Study
                  </Link>
                </li>
                <li className="listItem d-none" key="etnetwork-case-study">
                  <Link to={`/etnetwork-case-study/`}>
                    ETNetwork Case Study
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-md-flex flex-md-column customer-support ">
              <div className="d-md-flex justify-content-md-between col-md-12 w-100">
                <div className="linksCol w-100  me-3">
                  <label className="flinksHead">{footerData[4]}</label>
                  <ul className="linksList">
                    {platforms.map((service, index) => {
                      return service.src === "aws" ? (
                        <li key={index} className="listItem">
                          <Link to={`/aws-public-support-statement/`}>
                            {service.name}
                          </Link>
                        </li>
                      ) : (
                        <li key={index} className="listItem">
                          <Link to={`/cloud-platforms/#${service.src}`}>
                            {service.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="linksCol w-100">
                  <label className="flinksHead">{footerData[5]}</label>
                  <ul className="linksList">
                    {company.map((service) => (
                      <li className="listItem" key={service.src}>
                        <Link to={`/${service.src}/`}>{service.name}</Link>
                      </li>
                    ))}
                    <li className="listItem text-start" key="update_cookie_preferences">
                      <a href="#" id="open_preferences_center">
                        Update cookies preferences
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="customer-support-card my-md-4">
              <label className="flinksHead text-uppercase  d-md-flex justify-content-md-center">Customer Support</label>
                <a
                  target="_blank"
                  href={`https://support.opsio.se/${
                      locale === "en-IN" || locale === "en" ? "en"  : ""
                    }/`}
                  className="card d-md-flex justify-content-md-between align-items-md-center p-3 cursor-pointer justify-content-around"
                >
                  <div>
                    <img
                      src={message}
                      alt="message"
                      className="text-white "
                      width="30px"
                      height="30px"
                      loading="lazy"
                    />
                  </div>
                  <div className="d-md-flex flex-md-column justify-content-md-center me-2">
                    <p className="m-0 mb-2 text-center">Create Support Ticket</p>
                    <p className="m-0 text-center">
                      Contact our support for help
                    </p>
                  </div>

                  <div >
                    <img
                      src={arrowRight}
                      alt="arrow right"
                      className="mt-md-2"
                      width="30px"
                      height="30px"
                      loading="lazy"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="contactInfo">
            <div className="infoWrap d-md-flex justify-content-md-between align-items-md-center">
              <div className="logoBox me-md-5 mb-3">
                <Link to={`/`}>
                  {" "}
                  <img src={opsio} alt="opsio" loading="lazy"/>{" "}
                </Link>
              </div>
              <ul className="fcontactList mb-md-0 d-md-flex justify-content-md-between w-100">
                <li className="fcontactItem me-md-5 address-container">
                  <AddressIcon />
                  <p >
                    {data.contact.address1},{" "}
                    {data.contact.address2}
                  </p>
                </li>
                <li className="fcontactItem me-md-5 col-md-3">
                  <PhoneIcon />
                  <a
                    href={`tel:${data.contact.phone}`}
                    style={{ textDecoration: "none" }}
                    className="me-md-3 "
                  >
                  <p className="lh-1">{data.contact.phone}</p>
                  </a>
                </li>

                <li className="fcontactItem">
                  <MailIcon />
                  <a
                    href={`mailto:${data.contact.email}`}
                    style={{ textDecoration: "none" }}
                    className="me-md-3 "
                  >
                    {" "}
                    <p>{data.contact.email}</p>
                  </a>
                </li>
              </ul>
              <ul className="fsocialList mb-md-0">
                <li className="fsocialItem">
                  <a
                    href={`https://www.linkedin.com/company/${
                      locale === "en-IN" ? "opsio-india" : "opsiosweden"
                    }/`}
                  >
                    {" "}
                    <LinkdinIcon />
                  </a>
                </li>
                <li className="fsocialItem">
                  <a href="https://twitter.com/opsiocloud?lang=en">
                    {" "}
                    <TwitterIcon />
                  </a>
                </li>
                <li className="fsocialItem">
                  <a href="https://www.facebook.com/opsiosverige/">
                    <FacebookIocn />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyRightbox">
            <p>© {new Date().getFullYear()} Opsio - All rights reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
